import React from 'react'
import './sectionHeader.css'
import MainSelectBox from '../MainSelectBox';
import { EarthIcon } from '../icons';


const SectionHeader = ({
  title,
  blueText,
  subtitle,
  options,
  defaultText,
  id,
  onActive,
  active,
}) => {
  return (
    <div className="section__header">
      <div>
        <h4 className="section__title">
          {title} <span className="blue">{blueText}</span>
        </h4>
        <p className="section__subtitle">{subtitle}</p>
      </div>
      <div style={{ zIndex: "10" }}>
        <MainSelectBox
          options={options}
          onActive={onActive}
          active={active}
          defaultText={defaultText}
          id={id}
          color="blue"
          icon={<EarthIcon />}
        />
      </div>
    </div>
  );
};

export default SectionHeader