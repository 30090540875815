import React from "react";
// import SocialBox from "../SocialBox";
// import { FacebookIcon } from "../icons";
import EventBox from "../EventBox";

const EventsList = ({ loading, events }) => {

  if (!events?.length && !loading)
    return <h4 className={"empty__data"}>Please search to show results</h4>;

  return (
    <div className="social__list">
      {loading ? (
        <div className="loading__spinner">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
          {events?.map((event, i) => <EventBox key={i} event={event} />)}
        </>
      )}
    </div>
  );
};

export default EventsList;
