import React from 'react'
import Logo from '../../assets/logo.png'
import SaudFlag from "../../assets/saudi-arabia.png";
import  './header.css'
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div className='header'>
    <header className="header__content container">
      {/* logo */}
      <Link to='/'>
        <img src={Logo} alt="" className="header__logo" />
      </Link>
      {/* title */}
      <h2 className="header__title">Social Media Analytics Tool</h2>
      <nav className="header__nav">
        <div>DEMO</div>
        <span className="dividing"></span>
        <div>Help</div>
        <span className="dividing"></span>
        <div className='language__btn'>
          <img className='saud__flag' src={SaudFlag} alt="" /> عربي
        </div>
      </nav>
    </header>
    </div>
  );
}

export default Header