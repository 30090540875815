import React from "react";

const CustomBar = (props) => {
  const { x, y, width, height, fill } = props;

  // Define the height of each box and the space between boxes
  const boxHeight = 20.0989;
  const boxSpacing = 4.813; // Space between the top of one box and the top of the next box (24.9124 - 20.0989)

  // Calculate the number of boxes that can fit within the given height
  let boxCount = Math.floor(height / (boxHeight + boxSpacing));

  // Ensure at least one box is rendered
  boxCount = Math.max(boxCount, 1);

  // Calculate the total height of the boxes including spacing
  const totalBoxesHeight = boxCount * boxHeight + (boxCount - 1) * boxSpacing;

  // Calculate the starting y position to align the boxes with the x-axis
  const startY = height < boxHeight ? y - 5 : y - 5 + height - totalBoxesHeight;

  const boxes = [];
  for (let i = 0; i < boxCount; i++) {
    // Adjust the height of the last box if it exceeds the remaining height
    const currentBoxHeight =
      i === boxCount - 1 ? height - i * (boxHeight + boxSpacing) : boxHeight;

    boxes.push(
      <rect
        key={i}
        x={x}
        y={startY + i * (boxHeight + boxSpacing)}
        width={width}
        height={Math.min(currentBoxHeight, boxHeight)} // Adjust height if column is smaller than box height
        rx={5} // Rounded corners
        fill={fill}
      />
    );
  }

  return <g>{boxes}</g>;
};

export default CustomBar;
