import React, { useEffect, useState } from "react";
import styles from "./.module.scss";
import { useApi } from "../hooks/useApi";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
// import { IoCheckmarkSharp } from "react-icons/io5";
// import { IoMdAdd } from "react-icons/io";
import Cookies from "js-cookie";

const EventDetailsPage = () => {
  const { loading, event, onRequest, error } = useApi("api/linkedin/details", "post");
  const { id } = useParams();
  const linkedInCookies = Cookies.get("localLinkedCookies");
  const navigate = useNavigate();

  useEffect(() => {
    if (!linkedInCookies) {
      navigate('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkedInCookies]);

  useEffect(() => {
    if (id && linkedInCookies) {
      onRequest({
        eventId: id,
        cockie: linkedInCookies,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, linkedInCookies]);

  useEffect(() => {
    if (error) {
      Cookies.remove("localLinkedCookies");
      navigate('/')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <div className={`${styles.event__page} container`}>
      {loading ? (
        "Loading...."
      ) : linkedInCookies && event ? (
        <>
          <h4 className={styles.title}>{event?.name}</h4>
          <div className={styles.image}>
            <img
              src={
                // event?.image ||
                "https://cdn.avalos.sv/wp-content/uploads/default-featured-image.png"
              }
              alt=""
            />
            <span className={styles.date}>
              {event?.date?.includes(" - ")
                ? moment(
                    event?.date?.slice(0, event?.date?.indexOf("-")) + "2024"
                  ).format("D / MMM / YYYY")
                : event?.date?.includes("CEST")
                ? moment
                    .tz(
                      event?.date,
                      "ddd, MMM D, YYYY, h:mm A",
                      "Europe/Berlin"
                    )
                    .format("D / MMM / YYYY")
                : event?.date?.includes("UTC")
                ? moment.utc(event?.date).format("D / MMM / YYYY")
                : moment(event?.date, "ddd, MMM D, YYYY, h:mm A").format(
                    "D / MMM / YYYY"
                  ) === "Invalid date"
                ? "No date available"
                : moment(event?.date, "ddd, MMM D, YYYY, h:mm A").format(
                    "D / MMM / YYYY"
                  )}
            </span>
          </div>
          <h6 className={styles.count__title}>Counts:</h6>
          <div className={styles.count__section}>
            <div className={styles.count__box}>
              <span className={styles.count__label}>Attendance: </span>
              <span className={styles.count__val}>
                {event?.numOfAttendees?.split(" ")[0]}
              </span>
            </div>
            <div className={styles.count__box}>
              <span className={styles.count__label}>RePosts: </span>
              <span className={styles.count__val}>
                {event?.repostsCount?.split(" ")[0]}
              </span>
            </div>
            <div className={styles.count__box}>
              <span className={styles.count__label}>Reactions: </span>
              <span className={styles.count__val}>{event?.reactionsCount}</span>
            </div>
            <div className={styles.count__box}>
              <span className={styles.count__label}>Comments: </span>
              <span className={styles.count__val}>
                {event?.commentsCount?.split(" ")[0]}
              </span>
            </div>
          </div>
          <div className={styles.comments__list}>
            {event?.comments?.map((comment, i) => (
              <div key={i} className={styles.comment__box}>
                <span className={styles.comment__name}>{comment?.author}</span>
                <p className={styles.comment}>{comment?.content}</p>
              </div>
            ))}
          </div>
        </>
      ) : (
        <p style={{ textAlign: "center" }}>Please Add Linked in Cookie</p>
      )}
    </div>
  );
};

export default EventDetailsPage;
