import React from 'react'
import { IoSearchOutline } from "react-icons/io5";
import './searchbox.css'

const SearchBox = ({ setText, text, onSubmit, disabled, loading }) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      className="search__box"
    >
      <span className="search__icon">
        <IoSearchOutline />
      </span>
      <input
        onChange={(e) => setText(e.target.value)}
        type="text"
        className="search__input"
        placeholder="Search keywords here"
        value={text || ""}
      />
      <button
        type="submit"
        className={"search__btn"}
        disabled={disabled || loading}
      >
        {loading ? "loading..." : "Search"}
      </button>
    </form>
  );
};

export default SearchBox