/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SearchBox from "./components/SearchBox";
import SectionHeader from "./components/SectionHeader";
import MainBarChart from "./components/MainBarChart";
import { useApi } from "./hooks/useApi";
import EventsList from "./components/EventsList";
import { IoMdAdd } from "react-icons/io";
import { IoCheckmarkSharp } from "react-icons/io5";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const selectBoxData = {
  defaultText: "see all platforms",
  id: "select-projects-status",
  options: [
    // { id: "all", label: "All" },
    { id: "facebook", label: "facebook" },
    { id: "linkedin", label: "linkedin" },
  ],
};

const Home = () => {
  const [eventType, setEventType] = useState({
    id: "facebook",
    label: "facebook",
  });
  const [chartType, setChartType] = useState({
    id: "facebook",
    label: "facebook",
  });
  const [text, setText] = useState("");
  const [hashtag, setHashtag] = useState("");
  const [currentCookies, setCurrentCookies] = useState(null);
  const [localCookies, setLocalCookies] = useState(false);

  useEffect(() => {
    const linkedinCookies = Cookies.get("localLinkedCookies");
    if (linkedinCookies) {
      setCurrentCookies(linkedinCookies);
      setLocalCookies(true);
    }
  }, []);

  // get events
  const { events, loading: eventsLoading, onGetEvents, error: eventsError } = useApi();

  const onGetCurrentEvents = async () => {
    if (
      (chartType?.id === "linkedin" || chartType?.id === "all") &&
      !localCookies
    ) {
      alert("Please add linkedin cookies to have results with ");
    } else {
      await onGetEvents(
        {
          platforms: ["facebook"],
          keyword: text,
          cockie: currentCookies,
        },
        eventType?.id
      );
    }
  };

  useEffect(() => {
    hashtag && onGetCurrentEvents();
  }, [eventType]);

  // =============== CHARTS ==========
  // get charts
  const {
    chartData: ChartData,
    loading: chartLoading,
    onGetChartsData: onGetCharts,
    error: chartError
  } = useApi();

  const onGetCurrentChart = async () => {
    if (
      (chartType?.id === "linkedin" || chartType?.id === "all") &&
      !localCookies
    ) {
      alert("Please add linkedin cookies to have results with ");
    } else {
      await onGetCharts(
        {
          platforms: ["facebook"],
          keyword: text,
          cockie: currentCookies,
        },
        chartType?.id
      );
    }
  };

  useEffect(() => {
    hashtag && onGetCurrentChart();
  }, [chartType]);

  // ========== savedToken ========================== //
  const savedToken = () => {
    setLocalCookies(true);
    Cookies.set("localLinkedCookies", currentCookies);
    toast.success("saved!");
  };

  // ======== Search ============= //
  const onSubmit = async () => {
    setHashtag(text);
    text && (await onGetCurrentChart());
    text && (await onGetCurrentEvents());
  };

  useEffect(() => {
    if (chartError || eventsError) {
      if (chartType?.id === "linkedin" || eventType?.id === "linkedin") {
        setCurrentCookies(null);
        setLocalCookies(false);
        Cookies.remove("localLinkedCookies");
        toast.warning("Need To Add Linkedin Cookies");
      } 
    }
  }, [chartError, eventsError]);

  return (
    <div className="home__page container">
      <h3 className="home__title">
        Search keywords here to find the best price
      </h3>
      <p className="home__subtitle">
        all what you want to do is serch with key words then let our magic work
      </p>
      <SearchBox
        setText={setText}
        text={text}
        onSubmit={onSubmit}
        disabled={
          (eventType?.id === "linkedin" ||
            eventType?.id === "all" ||
            chartType?.id === "linkedin" || chartLoading || eventsLoading ||
            chartType?.id === "all") &&
          !localCookies
        }
        loading={chartLoading || eventsLoading}
      />
      {(chartType?.id === "linkedin" || eventType?.id === "linkedin") && !localCookies ? (
        <div className="cookies__box">
          <input
            type="text"
            value={currentCookies || ""}
            onChange={(e) => setCurrentCookies(e?.target?.value)}
          />
          <button
            disabled={!currentCookies}
            className={`add_cookies_btn ${localCookies ? "active__btn" : ""}`}
            onClick={savedToken}
          >
            {localCookies ? <IoCheckmarkSharp /> : <IoMdAdd />}
          </button>
        </div>
      ) : (
        <></>
      )}
      <div>
        <SectionHeader
          title="Analytics for"
          blueText={`#${hashtag}`}
          // subtitle="all the information about social media traffic"
          defaultText={selectBoxData?.defaultText}
          options={selectBoxData?.options}
          id={"select-box-1"}
          active={chartType}
          onActive={setChartType}
        />
      </div>
      <MainBarChart
        data={ChartData}
        loading={chartLoading}
        type={chartType?.id}
      />
      <section>
        <SectionHeader
          title="Events for"
          blueText={`#${hashtag}`}
          subtitle="All the information's collected form the social media platforms"
          defaultText={selectBoxData?.defaultText}
          options={selectBoxData?.options}
          id={"select-box-2"}
          active={eventType}
          onActive={setEventType}
        />
        <EventsList loading={eventsLoading} events={events} />
      </section>
    </div>
  );
};

export default Home;
