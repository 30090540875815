

export const FacebookEvents = {
  message: "Events from facebook",
  keyword: "iphone",
  events: [
    {
      name: "Iphone 15 pro",
      date: "2025-02-07T16:00:00.000Z",
      link: "https://www.facebook.com/events/766072995629656/",
      image:
        "https://scontent-iad3-1.xx.fbcdn.net/v/t39.30808-6/437712490_1101219567876069_942661748547136214_n.jpg?stp=c0.24.168.112a_dst-jpg_p168x128&_nc_cat=108&ccb=1-7&_nc_sid=75d36f&_nc_ohc=izlOPNZyfvoQ7kNvgGpJ05r&_nc_ht=scontent-iad3-1.xx&oh=00_AYDRl5buDKVr8Uhtq7zivOLPhIDtunE-YGwFFCBjv1SDNQ&oe=669B03F3",
      numOfAttendees: 224,
    },
    {
      name: "Iphone 7plus ",
      date: "2025-01-12T15:51:00.000Z",
      link: "https://www.facebook.com/events/820161513338086/",
      image:
        "https://scontent-iad3-1.xx.fbcdn.net/v/t39.30808-6/441158873_122122562834266286_8727516206287876927_n.jpg?stp=c0.37.168.112a_dst-jpg_p168x128&_nc_cat=101&ccb=1-7&_nc_sid=75d36f&_nc_ohc=oBdrLfuV8wEQ7kNvgHtGQxP&_nc_ht=scontent-iad3-1.xx&oh=00_AYClQxJdIv2t-oG2LZHToHZI9uCF1pIJBIGHmBnXTS-7lg&oe=669B0A7A",
      numOfAttendees: 77,
    },
    {
      name: "iPhone XR ",
      date: "2024-08-11T00:00:00.000Z",
      link: "https://www.facebook.com/events/765844675319561/",
      image:
        "https://scontent-iad3-1.xx.fbcdn.net/v/t39.30808-6/436120867_122110702376275693_6473459781411759015_n.jpg?stp=c0.18.168.112a_dst-jpg_p168x128&_nc_cat=107&ccb=1-7&_nc_sid=75d36f&_nc_ohc=BIIPXP-J4dcQ7kNvgExdHbG&_nc_ht=scontent-iad3-1.xx&oh=00_AYCIMSGQ94H08s6zUb5l30BkPZdEnl0G8G8CRrWPXwvPEQ&oe=669ADF7B",
      numOfAttendees: 165,
    },
    {
      name: "iphone x 256gb",
      date: "2024-07-05T12:00:00.000Z",
      link: "https://www.facebook.com/events/1430319258360648/",
      image:
        "https://scontent-iad3-1.xx.fbcdn.net/v/t39.30808-6/449836518_1556728448211500_8085409144694010435_n.jpg?stp=c0.37.168.112a_dst-jpg_p168x128&_nc_cat=107&ccb=1-7&_nc_sid=75d36f&_nc_ohc=84UEcVWJDMkQ7kNvgErRsE1&_nc_ht=scontent-iad3-1.xx&oh=00_AYCdHIAarFYFA73sdSjWRPkOgmYerXp5A1tANxlD4PbcPw&oe=669AF174",
      numOfAttendees: 4,
    },
    {
      name: "iPhone 7 plus",
      date: "2024-12-13T21:00:00.000Z",
      link: "https://www.facebook.com/events/651088120295314/",
      numOfAttendees: 111,
    },
    {
      name: "Iphone x 256",
      date: "2024-07-12T12:00:00.000Z",
      link: "https://www.facebook.com/events/1739189133553581/",
      image:
        "https://scontent-iad3-1.xx.fbcdn.net/v/t39.30808-6/451090434_1533503720907128_2847567601229922708_n.jpg?stp=c0.37.168.112a_cp6_dst-jpg_p168x128&_nc_cat=108&ccb=1-7&_nc_sid=75d36f&_nc_ohc=PVE6qOZs0wgQ7kNvgEAmp6-&_nc_ht=scontent-iad3-1.xx&oh=00_AYAiaHB83O_9qcV4Uw6eCA1Z7yE2kHFI4RrsaKdW7RmyKw&oe=669AFFF7",
      numOfAttendees: 1,
    },
    {
      name: "IPhone x",
      date: "2024-07-15T15:00:00.000Z",
      link: "https://www.facebook.com/events/459712276766528/",
      numOfAttendees: 1,
    },
    {
      name: "iPhone ",
      date: "2024-07-15T12:00:00.000Z",
      link: "https://www.facebook.com/events/1411227509541450/",
      numOfAttendees: 1,
    },
    {
      name: "IPhone 6n ",
      date: "2024-07-15T15:00:00.000Z",
      link: "https://www.facebook.com/events/1604377007084603/",
      numOfAttendees: 1,
    },
    {
      name: "iphone ",
      date: "2026-02-28T07:48:00.000Z",
      link: "https://www.facebook.com/events/943985177092147/",
      image:
        "https://scontent-iad3-2.xx.fbcdn.net/v/t39.30808-6/430056898_387439257558014_4398211340620549263_n.png?stp=c153.0.1566.1044a_dst-jpg_s168x128&_nc_cat=105&ccb=1-7&_nc_sid=75d36f&_nc_ohc=pX3495mxdy8Q7kNvgG-k568&_nc_ht=scontent-iad3-2.xx&oh=00_AYD92eEB7EkhSZ_tRkPuGxhvW3qCVBj07x5jedkE_DK60Q&oe=669AE6F5",
      numOfAttendees: 5,
    },
    {
      name: "IPHONE 11",
      date: "2024-07-15T21:54:00.000Z",
      link: "https://www.facebook.com/events/1638239050329007/",
      image:
        "https://scontent-atl3-2.xx.fbcdn.net/v/t39.30808-6/451249734_1172498887426436_2321035873791900199_n.jpg?stp=c0.37.168.112a_dst-jpg_p168x128&_nc_cat=105&ccb=1-7&_nc_sid=75d36f&_nc_ohc=jnco1Uf8Q7UQ7kNvgEXA9-v&_nc_ht=scontent-atl3-2.xx&oh=00_AYCAhJ5g5aJTdlsH6rUGmtdukVCdR_OKn6OwljBQeClRNg&oe=669B0B81",
      numOfAttendees: 1,
    },
    {
      name: "Bypass iPhone Vs Non Pta iphone",
      date: "2024-07-15T12:00:00.000Z",
      link: "https://www.facebook.com/events/798669332463026/",
      image:
        "https://scontent.fpit1-1.fna.fbcdn.net/v/t39.30808-6/451050847_122161779878199383_3856735068727554024_n.jpg?stp=c110.0.1023.682a_dst-jpg_s168x128&_nc_cat=100&ccb=1-7&_nc_sid=75d36f&_nc_ohc=xl0cN8NFsCEQ7kNvgFyAb6A&_nc_ht=scontent.fpit1-1.fna&oh=00_AYDnHsm4uKhIB2ZcC5FBjvfTFQcaMt-d8AVSQAPKwTPF7g&oe=669B0693",
      numOfAttendees: 1,
    },
    {
      name: "Promotion iPhone ",
      date: "2024-07-09T13:00:00.000Z",
      link: "https://www.facebook.com/events/508498168304252/",
      image:
        "https://scontent-iad3-1.xx.fbcdn.net/v/t39.30808-6/449930533_513535164347012_7685889530151614442_n.jpg?stp=c0.18.168.112a_dst-jpg_p168x128&_nc_cat=108&ccb=1-7&_nc_sid=75d36f&_nc_ohc=AW1S50KL6nAQ7kNvgF25aMD&_nc_ht=scontent-iad3-1.xx&oh=00_AYDzCblqQ-TThoahsndZ7hDrNvajno7ACONLxFF8E6n16Q&oe=669AEB98",
      numOfAttendees: 1,
    },
    {
      name: "iPhone 11 Pro",
      date: "2024-10-09T23:00:00.000Z",
      link: "https://www.facebook.com/events/490831233439191/",
      image:
        "https://scontent-iad3-1.xx.fbcdn.net/v/t39.30808-6/449518763_1533730220890419_959289549563571925_n.jpg?stp=c154.0.541.361a_dst-jpg_s168x128&_nc_cat=107&ccb=1-7&_nc_sid=75d36f&_nc_ohc=nrM6sdRs4BMQ7kNvgEDYN6H&_nc_ht=scontent-iad3-1.xx&oh=00_AYD0rhM26RV-vl8Bc0fCNtYcHiH4cHOKEXbWGt0w2CtNDQ&oe=669AE43F",
      numOfAttendees: 17,
    },
    {
      name: "iPhone Stock Sale",
      date: "2024-07-16T17:00:00.000Z",
      link: "https://www.facebook.com/events/1673129686559336/",
      image:
        "https://scontent-mia3-1.xx.fbcdn.net/v/t39.30808-6/449831673_122101982330409723_4042595251861788523_n.png?stp=c153.0.1566.1044a_dst-jpg_s168x128&_nc_cat=104&ccb=1-7&_nc_sid=75d36f&_nc_ohc=6Zl0jRoyprMQ7kNvgEvHlCR&_nc_ht=scontent-mia3-1.xx&oh=00_AYDvC8_r0MDWEwtXU7X-cckuj_h8o5pTOab8mbc_JGduPQ&oe=669AE38C",
      numOfAttendees: 1,
    },
    {
      name: "Thu iphone cu",
      date: "2024-03-01T10:00:00.000Z",
      link: "https://www.facebook.com/events/1098305111491712/",
      image:
        "https://scontent.fdiq4-1.fna.fbcdn.net/v/t39.30808-6/429582680_380415808072904_7295692556368683079_n.jpg?stp=c352.0.936.624a_dst-jpg_s168x128&_nc_cat=103&ccb=1-7&_nc_sid=75d36f&_nc_ohc=RPdgzez177wQ7kNvgE-ucp8&_nc_ht=scontent.fdiq4-1.fna&oh=00_AYATFOKzaKtWe6q2zI8U9IRtXSESFnGJr72Gf07nqJXYCw&oe=669B1018",
      numOfAttendees: 5,
    },
  ],
};

export const LinkedinEvents = {
  message: "Events from linkedin",
  keyword: "iphone",
  events: [
    {
      name: "WhatsApp for Business: Connect / Communicate / Network / Community / Sell",
      date: "Mon, Aug 5, 6:00 PM IST",
      link: "https://www.linkedin.com/events/6966062751756533760/",
      image:
        "https://media.licdn.com/dms/image/D4D24AQEOz3IEmoFlEw/video-liveannouncement-shrink_160_90/0/1696231383267?e=1721811600&v=beta&t=5pSh2XBuuB4mRNazN4syR_-vdmWIggvlnQfdqybwZZY",
      // numOfAttendees: "5,811 attendees",
      numOfAttendees: "100 attendees",
    },
    {
      name: "From launching the iPhone to experiencing a 20x growth in Wealthfront",
      date: "Today, 11:30 AM PDT",
      link: "https://www.linkedin.com/events/7218702704276082692/",
      image:
        "https://media.licdn.com/dms/image/D4D24AQEIZwuibohjdg/video-liveannouncement-shrink_160_90/0/1721072841048?e=1721811600&v=beta&t=rwuxh6VyQ24Syuepgc6aFt8JpMQU9O5fpIG5pfhrM7w",
      numOfAttendees: "17 attendees",
    },
    {
      name: "Movie Health PoC - Perfect Days: Exploring The Human Connection To Movies",
      date: "Sat, Jul 27, 2:00 PM AEST",
      link: "https://www.linkedin.com/events/7214795254779064320/",
      image:
        "https://media.licdn.com/dms/image/D561EAQG9OWxYW3wsiQ/event-background-image-crop_270_480/0/1721087903276?e=1721811600&v=beta&t=yOnL3OFdAyswiOH4aX0pD5MUNOh5F4EFykpXvWwKqdE",
      numOfAttendees: "2 attendees",
    },
    {
      name: "The 30 Days In 90 Minutes Content Creation System For Service Businesses",
      date: "Tue, Jul 23, 10:00 AM AEST",
      link: "https://www.linkedin.com/events/7208199214911148033/",
      image:
        "https://media.licdn.com/dms/image/D4E1EAQF_S1-iYBLCYw/event-background-image-crop_270_480/0/1719295191413?e=1721811600&v=beta&t=vDd_T43_w-h9RPqyqSXfW3tLvCDcDHI5Ce0MzMML11Y",
      numOfAttendees: "1 attendee",
    },
    {
      name: "Discover Mobile Photography",
      date: "Wed, Sep 4, 3:30 PM BST",
      link: "https://www.linkedin.com/events/7209488053277487104/",
      image:
        "https://media.licdn.com/dms/image/D4E1EAQF80HW3dUXhBA/event-background-image-crop_270_480/0/1718875896495?e=1721811600&v=beta&t=vbDRmMjleSKPZ9w0Glg-dF2Zh3ZeoDATHuHfzZDj8p4",
      numOfAttendees: "1 attendee",
    },
    {
      name: "Discover Apps",
      date: "Wed, Aug 7, 3:30 PM BST",
      link: "https://www.linkedin.com/events/7209153550134771712/",
      image:
        "https://media.licdn.com/dms/image/D4E1EAQFb4nwztsEQKg/event-background-image-crop_270_480/0/1718796144415?e=1721811600&v=beta&t=kCWAPXd1CA4qUTMGe2LvrNfDCKf07Vew74tSy2fErNQ",
      numOfAttendees: "1 attendee",
    },
    {
      name: "Simplify Your Digital and Printed Photos",
      date: "Tue, Sep 24, 7:00 PM EDT",
      link: "https://www.linkedin.com/events/7215898365455163393/",
      image:
        "https://media.licdn.com/dms/image/D561EAQF1-sDktgwgpw/event-background-image-crop_270_480/0/1720404232965?e=1721811600&v=beta&t=WFuJPmzIYx_IP1U77pu2Y3HVVv2aXbztP7kAAFhUD88",
      numOfAttendees: "3 attendees",
    },
    {
      name: "+1(833)562-1543 How to geek squad best buy iphone repair",
      date: "Fri, Jan 26 - Fri, Dec 13",
      link: "https://www.linkedin.com/events/7156590581035528192/",
      image:
        "https://media.licdn.com/dms/image/D561EAQGHLKFg0HNUwA/event-background-image-crop_270_480/0/1706264156809?e=1721811600&v=beta&t=mvGMRKEjyBpLYj2_iK8pZbDfkEqr1zpn4XqgvEwPFro",
      numOfAttendees: "2 attendees",
    },
    {
      name: "How Do I Cancel Geek Squad Protection Plan",
      date: "Wed, Dec 6 - Thu, Oct 10",
      link: "https://www.linkedin.com/events/7138060834781462528/",
      image:
        "https://media.licdn.com/dms/image/D561EAQEJktGTP6Rg3Q/event-background-image-crop_270_480/0/1701846320359?e=1721811600&v=beta&t=MXoznEYvATKSi4WWKHH0AY-YXqsm6OF7yWRLqQWsm9s",
      numOfAttendees: "3 attendees",
    },
    {
      name: "1–877-698-8051 Best Buy Return After Trade In - Full Guide",
      date: "Tue, Jan 2 - Thu, Nov 7",
      link: "https://www.linkedin.com/events/7147881496484192256/",
      image:
        "https://media.licdn.com/dms/image/D561EAQHWATE4-q982g/event-background-image-crop_270_480/0/1704187748919?e=1721811600&v=beta&t=UObMB1UaqExkpHB3SSK7vdWm-IyTuS_9zWYWthcAhSc",
      numOfAttendees: "2 attendees",
    },
  ],
};

export const linkedInEventDetails = {
  message: "Event details from linkedin",
  event: {
    name: "How To Break Into Product Management",
    date: "Fri, Aug 2, 2024, 2:00 PM - 2:30 PM (your local time)",
    image:
      "https://media.licdn.com/dms/image/D4D1EAQGNrNKRVbEjrQ/event-background-image-crop_720_1280/0/1666275710344?e=1722945600&v=beta&t=GyXr0Y7w_iHeMPaX-nc2PembzX5wL9fE1m31iM3SsoY",
    numOfAttendees: "1,965 attendees",
    eventCreator: "https://www.linkedin.com/company/dexnova-learning/posts",
    reactionsCount: "15",
    repostsCount: "17 reposts",
    commentsCount: "34 comments",
    comments: [
      {
        author: "Michael Effanga PMP PMI-PBA PMI-ACP MCTS SSGB CSM",
        content:
          "Collaboration is at the heart of Agile practice. Team members must collaborate with themselves and stakeholders to deliver a valuable product increment. How do you build your team to effectively collaborate?Learn tips and tricks of collaboration in this free Webinar.Join this free Webinar to learn about Agile and develop Agile Teams for Optimal Productivity.https://bit.ly/Agile_Teams",
      },
      {
        author: "Ahereza Levi",
        content:
          "Michael Effanga PMP PMI-PBA PMI-ACP MCTS SSGB CSM how will l get the long into the 04th , July zoom meeting",
      },
      {
        author: "Michael Effanga PMP PMI-PBA PMI-ACP MCTS SSGB CSM",
        content:
          "According to research by Digital.ai, Agile adoption in software teams increased from 37% in 2020 to 86% in 2021. The numbers keep growing as more teams and companies embrace a more effective way of delivering results. Join this free Webinar to learn about Agile and develop Agile Teams for Optimal Productivity.https://bit.ly/Agile_Teams",
      },
      {
        author: "DexNova Learning",
        content:
          "MODELS OF SUPPLY CHAIN • Continuous flow (High volume same product repeatedly)• Fast chain (Frequent product line change & short life span)• Efficient chain (Hyper competitive Coys, relies on forecasting)• Agile (Products in small batches, requires less automation more expertise) • Custom-configured (custom-configuration model is combination of the agile and continuous flow models)• Flexible (Light switch model can absorb high & low demand)",
      },
      {
        author: "Michael Effanga PMP PMI-PBA PMI-ACP MCTS SSGB CSM",
        content:
          "Register in advance for this meeting:https://us06web.zoom.us/meeting/register/tZYsfu-ppzwrGdHiOBHWhShOlTb0jYsyHcyIDo you know that Product management is a high-demand field, as companies in a wide range of industries increasingly recognise the importance of having a dedicated team to oversee the development and success of their products? Join DexNova Learning free webinar on How To Break Into Product Management with Swosti Panda (Application  Product Manager at Google).Register in advance for this meeting:https://us06web.zoom.us/meeting/register/tZYsfu-ppzwrGdHiOBHWhShOlTb0jYsyHcyI",
      },
      {
        author: "Michael Effanga PMP PMI-PBA PMI-ACP MCTS SSGB CSM",
        content:
          "Would you like to get a professional Certificate from CIML, USA?The Chartered Institute of Management and Leadership is an American professional body that seeks to develop managerial skills in employers and employees.They are committed to helping managers achieve qualifications that would meet managerial standards all over the world.Dexnova Consulting Limited in collaboration with the @Chartered Institute of Management and Leadership(CIML), USA is offering you an opportunity to get a Diploma & Advance Diploma Certificate in the following courses:1. Entrepreneurship & Business Management2. Human Resource Management3. Management & Administration4. Procurement & Supply Chain ManagementBenefits:-100% Online with Learning Supports-Learn at your own pace and time-Pathway to becoming an Associate or Full Member of Chartered Institute of Management and Leadership, USA.-Professional Certificate from CIML, USA.Admission for September Session is currently on:https://bit.ly/CIML_USA",
      },
      {
        author: "DexNova Learning",
        content:
          "Every ship needs a captain for navigation and stewardship.Every product needs a product manager as a captain to overseeing the development and lifecycle of a product, from inception to retirement. This role involves understanding the market and customer needs, defining the product vision and strategy, and working with cross-functional teams to bring the product to market.Product Management is a field with a positive outlook in 2023 and a high earning potential.Join Swosti Panda, an Application Product Manager at Google as she discusses How to break into Product Management.The event here holds on Friday, 10th February by 5:30pm (GMT), register here:https://us06web.zoom.us/meeting/register/tZYsfu-ppzwrGdHiOBHWhShOlTb0jYsyHcyI",
      },
      {
        author: "Michael Effanga PMP PMI-PBA PMI-ACP MCTS SSGB CSM",
        content:
          "Excellence does not happen by chance. Behind every successful product is a methodic design process targeted at delivering a predetermined result to a specific audience.Join us this Friday and learn the steps toward Product Design Thinkinghttps://bit.ly/Dex_Product_Design",
      },
      {
        author: "Michael Effanga PMP PMI-PBA PMI-ACP MCTS SSGB CSM",
        content:
          "How well do you know your clients? Without proper information about the typical product users and their pain points, your product will be a misfit. Learn how to get deep insight about clients using Empathy MapJoin us this Friday and learn the steps towards Product Design Thinkinghttps://bit.ly/Dex_Product_Design",
      },
      {
        author: "Michael Effanga PMP PMI-PBA PMI-ACP MCTS SSGB CSM",
        content:
          "A recent research concludes that Breaking into Product Management is now possible for more backgrounds than ever. Professions from diverse backgrounds, including engineering, computer science, business, design, etc are making exceptional impact as product managers. Join us on Friday 4 November to learn how to break into Product ManagementRegister Here: https://bit.ly/Break_Into_ProductManagement",
      },
      {
        author: "DexNova Learning",
        content:
          "The career outlook for product managers is generally positive. Product management is a high-demand field, as companies in a wide range of industries are increasingly recognizing the importance of having a dedicated team to oversee the development and success of their products. According to data from the Bureau of Labor Statistics, employment of management occupations, which includes product managers, is projected to grow 7 percent from 2019 to 2029, faster than the average for all occupations.The How to Break into Product Management Free Webinar will hold Friday, 10th February, 2023 by 5:30pm (GMT), Register here: https://us06web.zoom.us/meeting/register/tZYsfu-ppzwrGdHiOBHWhShOlTb0jYsyHcyI",
      },
    ],
  },
};