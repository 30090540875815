import { useState } from "react";
import api from "../api";
import { toast } from "react-toastify";
import moment from "moment";
import { linkedInEventDetails, FacebookEvents } from "../data";

export const useApi = (endpoint, method) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [events, setEvents] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [event, setEvent] = useState(null);

  // get facebook events:=
  const onGetFacebookData = async (body) => {
    const res = await api.post(`/api/facebook/events`, {
      keyword: body?.keyword,
      // maxEvents: body?.maxEvents || 15,
    });
    // const res = FacebookEvents;
    return res?.events;
  };

  // get linkedin events:=
  const onGetLinkedinData = async (body) => {
    const res = await api.post("/api/linkedin", {
      cockie: body?.cockie,
      keyword: body?.keyword,
    });
    // const res = LinkedinEvents;
    return res?.events;
  };

  // get events handler:=
  const onGetEvents = async (body, type) => {
    setLoading(true);
    try {
      setLoading(true);
      // const res = await api[method](endpoint, body);
      if (type === "linkedin") {
        const linkedinEvents = await onGetLinkedinData(body);
        setEvents(
          linkedinEvents.map((item) => {
            return { ...item, type: "linkedin" };
          })
        );
        setLoading(false);
      }
      if (type === "facebook") {
        const facebookEvents = await onGetFacebookData(body);
        setEvents(
          facebookEvents.map((item) => {
            return { ...item, type: "facebook" };
          })
        );
        setLoading(false);
      }
      if (type === "all") {
        // add linkedin:=
        const linkedinEvents = await onGetLinkedinData(body);

        // add facebook:=
        const facebookEvents = await onGetFacebookData(body);

        setEvents(
          linkedinEvents
            .map((item) => {
              return { ...item, type: "linkedin" };
            })
            .concat(
              facebookEvents.map((item) => {
                return { ...item, type: "facebook" };
              })
            )
        );
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (typeof err?.response?.data?.message === "string") {
        toast.error(err?.response?.data?.message || "something went wrong!");
      } else {
        err?.response?.data?.message?.map((msg) =>
          toast.error(msg || "something went wrong!")
        );
      }
      setError(err?.response?.data?.message || "something went wrong!");
      setLoading(false);
      return error;
    }
  };

  // get charts handler:=
  const onGetChartsData = async (body, type) => {
    setLoading(true);
    try {
      setLoading(true);
      if (type === "facebook") {
        const facebookData = await onGetFacebookData({
          ...body,
          // maxEvents: 5,
        }); 
        const facebookCharts = facebookData.map((item) => {
          return {
            x: moment(item?.date).format("D MMM"),
            y: +item?.numOfAttendees,
          };
        });
        setChartData(facebookCharts);
        setLoading(false);
      }

      if (type === "linkedin") {
        const linkedinData = await onGetLinkedinData(body);
        // const linkedinData = [];
        const linkedChart = linkedinData
          ?.filter(
            (item) =>
              moment(item?.date, "ddd, MMM D, h:mm A z").format("D MMM") !==
              "Invalid date"
          )
          .map((item) => {
            return {
              x: moment(item?.date, "ddd, MMM D, h:mm A z").format("D MMM"),
              numOfAttendees: +item?.numOfAttendees
                .split(" ")[0]
                .replace(/,/g, ""),
            };
          });
        setChartData(linkedChart);
        setLoading(false);
      }

      if (type === "all") {
        // get facebook charts:=
        const facebookData = await onGetFacebookData({
          ...body,
          // maxEvents: 5,
        }); 
        const facebookCharts = facebookData.map((item) => {
          return {
            x: moment(item?.date).format("D MMM"),
            y: item?.commentsCount,
            numOfAttendees: item?.commentsCount,
          };
        });

        // get linkedin charts:=
        const linkedinData = await onGetLinkedinData(body);
        // const linkedChart = linkedinData
        //   ?.filter(
        //     (item) =>
        //       moment(item?.date, "ddd, MMM D, h:mm A z").format("D MMM") !==
        //       "Invalid date"
        //   )
        //   .map((item) => {
        //     return {
        //       x: moment(item?.date, "ddd, MMM D, h:mm A z").format("D MMM"),
        //       numOfAttendees: +item?.numOfAttendees
        //         .split(" ")[0]
        //         .replace(/,/g, ""),
        //       y: 0,
        //     };
        //   });

        // setChartData(facebookCharts.concat(linkedChart));
        setChartData(facebookCharts);
        setLoading(false);
      }
      setLoading(false)
    } catch (err) {
      if (typeof err?.response?.data?.message === "string") {
        toast.error(err?.response?.data?.message || "something went wrong!");
      } else {
        err?.response?.data?.message?.map((msg) =>
          toast.error(msg || "something went wrong!")
        );
      }
      setError(err?.response?.data?.message || "something went wrong!");
      setLoading(false);
      return error;
    }
  };

  const onRequest = async (body) => {
    setLoading(true);
    try {
      const res = await api[method](endpoint, body);
      // const res = linkedInEventDetails;
      setLoading(false);
      setEvent(res?.event);
    } catch(error) {
      setLoading(false);
      setError(error);
    }
    setLoading(false);
  }

  return {
    loading,
    error,
    chartData,
    events,
    event,
    onGetEvents,
    onGetChartsData,
    onRequest,
  };
};
