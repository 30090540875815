import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styles from './bar-chart.module.css'
import CustomBar from './CustomeBar';

const MainBarChart = ({ loading, data, type }) => {

  const totalEngagement = () => {
    let count;
    if (type === "linkedin") {
      count = data?.reduce((a, b) => {
        return a + +b?.numOfAttendees;
      }, 0);
    }else if(type === "facebook") {
      count = data?.reduce((a, b) => {
        return a + +b?.y;
      }, 0);
    } else if(type === "all") {
      const linkedinEngagement = data?.reduce((a, b) => {
        return a + +b?.numOfAttendees;
      }, 0);
      const facebookEngagement = data?.reduce((a, b) => {
        return a + +b?.y;
      }, 0);

      count = linkedinEngagement + facebookEngagement; 
    }
    return count;
  }

  if (!data?.length && !loading)
    return (
      <h4 className={styles.empty__data}>Please search to show results</h4>
    );

  return (
    <div className={styles.box}>
      <div className={styles.head}>
        <div>
          <p className={styles.total__engagement}>Total Event Attendance</p>
          <p className={styles.total__count}>{!loading && totalEngagement()}</p>
        </div>
      </div>
      {loading ? (
        <div className="loading__spinner">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.chart__container}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart width={500} height={300} barSize={27} data={data}>
                <XAxis dataKey="x" stroke="#707070" />
                <Tooltip />
                {(type === "facebook" || type === "all") && <Bar
                  dataKey="y"
                  fill="rgba(51, 127, 255, 1)"
                  shape={<CustomBar />}
                  startOffset={0}
                />}
                {(type === "linkedin" || type === "all") && <Bar
                  dataKey="numOfAttendees"
                  fill="#006699"
                  shape={<CustomBar />}
                  startOffset={0}
                />}
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className={styles.platforms}>
            <div className={styles.facebook}>
              <span className={styles.platform__color}></span>
              Facebook
            </div>
            <div className={styles.linkedin}>
              <span className={styles.platform__color}></span>
              linkedin
            </div>
            <div className={styles.tiktok}>
              <span className={styles.platform__color}></span>
              tiktok
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MainBarChart