import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { CheckedIcon, DropdownIcon } from "../icons";
import './main-select-box.css'

const MainSelectBox = ({
  defaultText,
  options,
  active,
  onActive,
  id,
  icon,
  color,
  width,
}) => {
  const [openMenu, setopenMenu] = useState(false);

  const closeDropMenu = (e) => {
    if (e?.target?.id !== id) {
      setopenMenu(false);
    } else {
      setopenMenu((prev) => !prev);
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", closeDropMenu);
    return () => {
      document.body.removeEventListener("click", closeDropMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`select_box_container ${active ? "" : "default"} ${
        color === "blue" ? "blue" : ""
      }`}
      style={{ minWidth: width ? "fit-content" : "" }}
    >
      <motion.button id={id} type="button" className={`select_box`}>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <span className={`icon`}>{icon}</span>
          <div>{active?.label || defaultText}</div>
        </div>
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ rotateX: openMenu ? 180 : 0 }}
          transition={{ duration: 0.3, delay: openMenu ? 0 : 0.3 }}
          className={`dropdown_icon`}
          style={{ display: "flex", alignItems: "center" }}
        >
          <DropdownIcon />
        </motion.div>
      </motion.button>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: openMenu ? 1 : 0,
          pointerEvents: openMenu ? "auto" : "none",
        }}
        transition={{ delay: openMenu ? 0 : 0.3 }}
        className={`drop_down_menu ${openMenu ? "opened" : ""}`}
      >
        {options &&
          options.map((item) => (
            <span
              key={item?.id}
              onClick={() => {
                setopenMenu(false);
                onActive(item);
              }}
              className={`option ${active?.id === item?.id ? "active" : ""} ${item?.disabled === true ? "disabled" : ""}`}
            >
              {item?.label}
              {item?.id === active?.id && <CheckedIcon />}
            </span>
          ))}
      </motion.div>
    </div>
  );
};

export default MainSelectBox;
