import React from 'react';
import './eventBox.css';
import { BsLinkedin } from "react-icons/bs";
import { ImFacebook2 } from "react-icons/im";
import moment from 'moment';
import "moment-timezone";
import { FaExternalLinkAlt } from "react-icons/fa";

const EventBox = ({ event }) => {

  return (
    <button
      onClick={(e) => {
        if (e?.target?.id !== "event__link") {
          window.open(
            event?.link?.split("/")[event?.link?.split("/")?.length - 2],
            "_blank"
          );
        }
      }}
      className="event__box"
    >
      {event?.type === "linkedin" ? (
        <BsLinkedin className="linkedin-icon" />
      ) : (
        <ImFacebook2 className="linkedin-icon" />
      )}
      <a
        href={event?.link}
        onClick={(e) => e.stopPropagation()}
        id="event__link"
        target="_blank"
        rel="noreferrer"
      >
        <FaExternalLinkAlt id="event__link" className="link-icon" />
      </a>
      <div className="event__img">
        <img
          src={
            event?.image ||
            "https://cdn.avalos.sv/wp-content/uploads/default-featured-image.png"
          }
          alt="event__image"
        />
      </div>
      <div className="event__details">
        <h4 className="event__name">{event?.name}</h4>
        <p className="event__numOfAttendees">
          {event?.numOfAttendees?.toString()?.includes("attendees")
            ? event?.numOfAttendees
            : event?.numOfAttendees + " attendees"}
        </p>
        <span className="event__date">
          {event?.type === "facebook"
            ? moment(event?.date).format("D / MMM / YYYY")
            : event?.date?.includes(" - ")
            ? moment(
                event?.date?.slice(0, event?.date?.indexOf("-")) + "2024"
              ).format("D / MMM / YYYY")
            : event?.date?.includes("CEST")
            ? moment
                .tz(event?.date, "ddd, MMM D, h:mm A", "Europe/Berlin")
                .format("D / MMM / YYYY")
            : event?.date?.includes("UTC")
            ? moment.utc(event?.date).format("D / MMM / YYYY")
            : moment(event?.date, "ddd, MMM D, h:mm A Z").format(
                "D / MMM / YYYY"
              ) === "Invalid date"
            ? "No date available"
            : moment(event?.date, "ddd, MMM D, h:mm A Z").format(
                "D / MMM / YYYY"
              )}
          {/* {dayjs(event?.date).format("{YYYY} MM-DDTHH:mm:ss SSS [Z] A")} */}
        </span>
      </div>
    </button>
  );
}

export default EventBox